.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  max-width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 20px;
}

.container h2 {
  padding-right: 10px;
}

.container input[type="text"] {
  width: 100%;
  height: 100%;
  font-size: 16px;
  padding-left: 10px;
}

.button {
  margin-left: 10px;
  width: 100px;
  height: 100%;
  background-color: black;
  color: white;
  font-size: 16px;
  cursor: pointer;
  border: none;
}

.spotify_logo {
  height: 50px;
}
