.header {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.subheader {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 20px;
}

.container {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 200px;
  max-width: 500px;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.button {
  background-color: #1db954;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  width: 200px;
  height: 50px;
  margin-left: 20px;
  cursor: pointer;
}
