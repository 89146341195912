.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.spotify_logo {
  height: 25px;
  width: 80px;
  padding-left: 10px;
  padding-top: 13px;
  padding-bottom: 10px;
}

.export_to_library {
  font-size: 15px;
  padding-left: 5px;
  padding-right: 5px;
}

.export_to_library:hover {
  font-weight: bold;
  cursor: pointer;
}

.save_to_library {
  font-size: 15px;
  padding-left: 5px;
  padding-right: 5px;
}

.save_to_library:hover {
  font-weight: bold;
  cursor: pointer;
}

.back {
  font-size: 15px;
  padding-left: 5px;
  padding-right: 5px;
}

.back:hover {
  font-weight: bold;
  cursor: pointer;
}
