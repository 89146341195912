.container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 16px);
  width: 100%;
  overflow: hidden;
}

.vibeBox {
  flex: 0 0 auto;
  z-index: 1;
  margin-bottom: 10px;
}

.playlist {
  flex: 1 1 auto;
  overflow-y: scroll;
  margin: 0 auto;
  max-width: 800px;
  width: 100%;
}

.player {
  flex: 0 0 auto;
  z-index: 1;
}
