.container {
}

.playlist {
  list-style: none; /* remove leading bullet point */
  padding-left: 10px;
  padding-right: 10px;
}

.playlist-item {
  border-radius: 10px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}

.playlist-item h2 {
  font-size: 24px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 10px;
}

.playlist-item p {
  font-size: 18px;
  color: #777777;
  margin-top: 0;
}
