.song {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  overflow: hidden;
  padding-left: 10px;
  padding-right: 10px;
  flex-direction: row;
  align-items: center;
}

.song h3 {
  flex: 1;
  margin: 0;
  font-size: 1.25rem;
  font-weight: bold;
  color: #333;
  padding-right: 10px;
  text-overflow: ellipsis;
  text-align: left;
  max-width: 400px;
}

.song p {
  flex: 1 1;
  margin: 0;
  font-size: 1rem;
  color: #999;
  text-align: right;
}

.playButton {
  display: none;
}

.song:hover {
  background-color: #1db954;
  color: #fff;
  cursor: pointer;
}

.song:hover h3 {
  color: #fff;
}

.song:hover p {
  color: #eee;
}

.playlist {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: top;
  width: 100%;
  background-color: #fff;
  padding: 0;
  padding-left: 0px;
  padding-right: 0px;
}

.no_results {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  color: #999;
}

.song_img {
  width: 50px;
}

.song_title {
  padding-left: 10px;
}

.song .play_on_spotify {
  display: none;
}

.song:hover .play_on_spotify {
  display: block;
}

